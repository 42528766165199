import IPhone1415Pro1 from './components/IPhone1415Pro1';
import './global.css';
import "@rainbow-me/rainbowkit/styles.css";
import Staking from './components/Staking';

import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  bsc,
  sepolia,
  localhost
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const { chains, publicClient } = configureChains(
  [bsc],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID }),
    // jsonRpcProvider({
    //   rpc: (chain) => ({
    //     http: `http://127.0.0.1:7545`,
    //   }),
    // }),
    publicProvider(),
  ],
  );
  
  const { connectors } = getDefaultWallets({
    appName: 'GROKHEROES',
    projectId: process.env.REACT_APP_PROJECT_ID,
    chains
  });
  
  const wagmiConfig = createConfig({
    autoConnect: true,
    chains: [bsc],
    connectors,
    publicClient
})

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider 
        chains={chains} 
        theme={darkTheme({
          accentColor: '#1ea1f1',
          accentColorForeground: 'white',
          borderRadius: 'small',
          fontStack: 'system',
          overlayBlur: 'small',
          fotnFamily:'SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
        })}
      >
        <Staking/>
      </RainbowKitProvider>
    </WagmiConfig>

  )
}

export default App;
