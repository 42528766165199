// Main net
export const  contracts = {
    token:'0x061A3e7Ab6267696834e9973E3C16a3379B308cE',
    presale:'0xAd8515fA40D7f2807109d74Ad0e1328b44F1687c',
    staking:'0xecdF0fbdd3B4C62CCa6c2319Df47aa8B7F471005',
}


// Local Host

// export const  contracts = {
//     token:'0x5c96Dce4B055fC03aEb6672740698ce4c06A979f',
//     presale:'0xAd8515fA40D7f2807109d74Ad0e1328b44F1687c',
//     staking:'0xBf717D4bbd1B22c6A796d90888DF4c1E9a0191CE',
// }

