import styles from "./IPhone1415Pro1.module.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useBalance, useAccount, useContractRead, useContractWrite, useWaitForTransaction } from "wagmi";
import { contracts } from "../utils/Contracts";
import HDAOPresale from '../abis/HDAOPresale.json'
import HdaoStaking from '../abis/HdaoStaking.json'
import IERC20 from '../abis/IERC20.json'
import { ethers, parseEther, formatEther } from 'ethers';
import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function mFormat(num) {
    return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm' :  parseFloat(num).toFixed(2)
}

function getMonthByTopic(topic){
    var txt= "";
    if(topic == 1) {
        txt = '3 Months';
    }

    if(topic == 2) {
        txt = ' 6 Months';
    }

    if(topic == 3) {
        txt = '12 Months';
    }

    if(topic == 4) {
        txt = '48 Months';
    }

    return txt;
}

function getEpochToSimpleDate(epoch) {
    const d = new Date(Number(epoch*1000));                                        
    return d.toLocaleDateString() + " "+d.toLocaleTimeString();
}

const StakeCard = (
    {
        stake, 
        config, 
        address, 
        onClaimRewardClick,
        onUnstakeClick
    }) => {

    const {data: myReward, refetch: rMyReward} = useContractRead({
        ...config,
        functionName:'calculateReturns',
        args:[address, Number(stake.topic)],
        onSuccess(data){
            console.log('reward detail', data)
        },
        onError(error){
            console.log('reward error', error)
        }
    })

    return(
        <React.Fragment>
            <div  style={{padding:'10px 10px'}}>
                <CardContent style={{background:'#fff', borderRadius:'10px'}}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <h3 style={{padding:'5px'}}>Stake Detail</h3>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Apy
                                    </TableCell>
                                    <TableCell align="right">{Number(stake.apy)}%</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Stake Topic</TableCell>
                                    <TableCell align="right">{getMonthByTopic(Number(stake.topic))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Stake Amount</TableCell>
                                    <TableCell align="right">{parseFloat(formatEther(stake.amount)).toFixed(2)} HDAO</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Apy Reward</TableCell>
                                    <TableCell align="right">{Number(myReward) > 0 ? parseFloat(formatEther(myReward)).toFixed(2): 0} HDAO</TableCell>
                                </TableRow>                                
                                <TableRow>
                                    <TableCell component="th" scope="row">Stake Date</TableCell>
                                    <TableCell align="right">
                                        {getEpochToSimpleDate(Number(stake.stakeBlock))}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Unstake Date</TableCell>
                                    <TableCell align="right">
                                        {getEpochToSimpleDate(Number(stake.unStakeBlock))}
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                                <tr>
                                    {
                                        ((Number(myReward) > 0 ? Number(formatEther(myReward)):0) > 0) && 
                                        // (Number(stake.lastClaimBlock) * 1000 < Date()) && 
                                        Number(stake.topic) >= 3
                                        ?
                                        <td style={{padding:'10px 5px'}}>
                                            <Button onClick={onClaimRewardClick} variant="outlined">Claim Reward</Button>
                                        </td>
                                        :''
                                    }
                                    {
                                        Date.now() >= (Number(stake.unStakeBlock) * 1000)
                                        ?
                                        <td style={{padding:'10px 5px'}}>
                                            <Button onClick={onUnstakeClick} variant="outlined">Unstake</Button>
                                        </td>
                                        :''

                                    }
                                </tr>
                        </Table>
                    </TableContainer>
                </CardContent>

            </div>
        </React.Fragment>
    )
}
const Staking = () => {
    const { address } = useAccount();
    // const address = "0xd8C98B84755056d193837a5e5b7814c8f6b10590"
    const { data } = useBalance({address: address});
    const [stakeAmount, setStakeAmount] = useState(0);
    const [mintLoading, setMintLoading] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [isAllowance, setIsAllowance] = useState(false);
    const [referTagNumber, setReferTagNumber] = useState(84116);
    const [prAmount, setPrAmount] = useState(100);
    const [stakeTopic, setStakeTopic] = useState(4);

    const [txHash, setTxHash] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    var myTopics = [];

    const hdaoConfig = {
        address: contracts.staking,
        abi: HdaoStaking.abi
    };

    const tokenConfig = {
        address: contracts.token,
        abi: IERC20.abi
    }

    const { writeAsync: approve, error: approveError} = useContractWrite({
        ...tokenConfig,
        functionName: 'approve'
    });

    const { writeAsync: stakeNow, error: stakeError } = useContractWrite({
        ...hdaoConfig,
        functionName: 'stakeTokens'
    });

    const {data: registerDetail, refetch: rRegisterDetail} = useContractRead({
        ...hdaoConfig,
        functionName:'registerDetail',
        args:[address],
        onSuccess(data){
            if(data && Number(data[0]) != 0) {
                setIsRegister(true);
            }
        },
        onError(error){
            console.log('register error', error)
        }
    })

    const {data: myStakes, refetch: rMyStakes} = useContractRead({
        ...hdaoConfig,
        functionName:'getMyStakes',
        args:[address],
        onSuccess(data){
            console.log('stakes', data)
        }
    })
    
    const {data: tokenBalance, refetch: rTokenBalance} = useContractRead({
        ...tokenConfig,
        functionName:'balanceOf',
        args:[address],
        onSuccess(data){
            if(Number(data) <= 0.000001e18) return;
            setStakeAmount(Number(formatEther(data)))
        }
    })

    const {data: allowanceAmount, refetch: rAllowanceAmount} = useContractRead({
        ...tokenConfig,
        functionName:'allowance',
        args:[address, contracts.staking],
        onSuccess(data){
            if(Number(data) == 0) return;
            if(Number(formatEther(data)) >= Number(formatEther(tokenBalance))){
                setIsAllowance(true);
            }
            console.log('allowance detail', formatEther(data))
        }
    })


    const {refetch: waitTxFetch} = useWaitForTransaction({
        hash:txHash,
        onSuccess(receipt) {
            rRegisterDetail?.();
            rAllowanceAmount?.();
            rTokenBalance?.();
            rMyStakes?.();
        }
    })

    const { writeAsync: registerUser, error: registerError } = useContractWrite({
        ...hdaoConfig,
        functionName: 'registerUserName',
    });

    const { writeAsync: claimReward, error: claimError } = useContractWrite({
        ...hdaoConfig,
        functionName: 'claimReward',
    });

    const { writeAsync: unStake, error: unStakeError } = useContractWrite({
        ...hdaoConfig,
        functionName: 'unstake',
    });

    const onClaimRewardClick = async(topic) => {
        try {
            setMintLoading(true)
            await claimReward({
                args:[topic]
            }).then(tx => {
                setTxHash(tx.hash);
                waitTxFetch?.();
                setMintLoading(false);
            })
        } catch (error) {
            if(error.toString().includes('Stake: amount staked is 0')){
                setErrorMsg('The staked amount is 0!')
            } else if(error.toString().includes('Stake: claim not available for this staking type')){
                setErrorMsg('Claim not available for this staking topic!')
            }else if(error.toString().includes('Stake: not reached reward claim time')){
                setErrorMsg('Reward claim time not reached!')
            } else {
                setErrorMsg('Server error. Try again!')
            }

            setMintLoading(false);
            setShowError(true);
        } finally{
            setTimeout(() => {
                setErrorMsg('')
                setShowError(false);
            }, 4000);
        }
    }

    const onUnstakeClick = async(topic) => {
        try {
            setMintLoading(true)
            await unStake({
                args:[topic]
            }).then(tx => {
                setTxHash(tx.hash);
                waitTxFetch?.();
                setMintLoading(false);
            })
        } catch (error) {
            if(error.toString().includes('Stake: stake does not match')){
                setErrorMsg('Stake does not match!')
            } else if(error.toString().includes('Stake: 0 amount on staking')){
                setErrorMsg('Stake amount is 0!')
            }else if(error.toString().includes('Stake: unstake time not reached')){
                setErrorMsg('Unstake time has not reached!')
            } else {
                setErrorMsg('Server error. Try again!')
            }
            
            setMintLoading(false);
            setShowError(true);
        } finally{
            setTimeout(() => {
                setErrorMsg('')
                setShowError(false);
            }, 4000);
        }
    }

    const onApproveClick = async () => {
        try {
                setMintLoading(true)
                await approve({
                    args:[contracts.staking, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff']
                }).then(tx => {
                    setTxHash(tx.hash);
                    waitTxFetch?.();
                    setMintLoading(false);
                })
        } catch (error) {
            setMintLoading(false);
        } finally {
            setTimeout(() => {
                setErrorMsg('')
                setShowError(false);
            }, 4000);
        }
    }

    const onClickStakeNow = async() => {
        try {
            if(myTopics.includes(stakeTopic)){
                setErrorMsg("Already staked in this topic!")
                setShowError(true)
                return;
            }
            setMintLoading(true);
            await stakeNow({args:[ethers.parseUnits(stakeAmount.toString(), 'ether'), stakeTopic]
            }).then(tx => {
                setTxHash(tx.hash);
                waitTxFetch?.();
                setMintLoading(false);
            })
        } catch (error) {
            if(error.toString().includes('Stake: stake amount 0')){
                setErrorMsg('Can not stake 0 amount!')
            } else if(error.toString().includes('Stake: topic overflow')){
                setErrorMsg('This stake topic not available!')
            } else if(error.toString().includes('Stake: already staked in topic')){
                setErrorMsg('Already staked in this topic!')
            } else {
                setErrorMsg('Server error. Try again!')
            }
            
            setMintLoading(false);
            setShowError(true);
        } finally {
            setTimeout(() => {
                setErrorMsg('')
                setShowError(false);
            }, 4000);
        }
    }
    const onRegisterClick = async () => {
        try {
            setMintLoading(true);
            await registerUser({
                args: [referTagNumber]
            }).then(tx => {
                setTxHash(tx.hash);
                waitTxFetch?.();
                setMintLoading(false);
            })
        } catch (error) {
            if(error.toString().includes('Stake: did not provide refer tag')){
                setErrorMsg('Referer tag is required!')
            } else if(error.toString().includes('Stake: already registered')){
                setErrorMsg(error.toString())
            } else if(error.toString().includes('Stake: Refer tag does not exist!')){
                setErrorMsg('Refer tag does not exist!');
            } else {
                setErrorMsg('Server error. Try again!')
            }

            setMintLoading(false);
            setShowError(true);
        } finally {
            setTimeout(() => {
                setErrorMsg('')
                setShowError(false);
            }, 4000);
        }
    }

    useEffect(() => {
        const timeoutId = setInterval(() => {
            rMyStakes?.();
        }, 6000);      
        return () => clearInterval(timeoutId);
    })

    return (
        <div className={styles.iphone1415Pro1} style={{position:'relative',minHeight:'1000px', overflow:'scroll'}}>
            <CircularProgress 
                color="success"
                style={{
                    width:'60px',
                    height:'60px',
                    position:'absolute',
                    zIndex:9999,
                    top:'36%',
                    left:'40%',
                    background:'#000',
                    borderRadius:'50%',
                    display:(mintLoading?'block':'none')
                }} 
            />
    
            <div className={styles.frameParent} style={{zIndex:9999}}>
                <div className={styles.gh342Parent}>
                    <img className={styles.gh342Icon} alt="" src="/hdao1.jpg" />
                </div>
                {/* <img 
                    alt="Theme Vid" 
                    src="/hdao_choose_you.gif"
                    style={{
                        width:'350px',
                        border:'solid 1px #161515',
                        borderRadius:'18px',
                        marginTop:'-42px'
                    }}  
                /> */}
                <ConnectButton showBalance={false}  style={{gap:83}}/>
                <div className={styles.frameGroup} style={{width:'100%', display:'block'}}>
                    <div>
                        <div className={styles.balanceParent}>
                            <b className={styles.balance}>Balance</b>
                            <b className={styles.bnb}>{Number(tokenBalance)?mFormat(Number(formatEther(tokenBalance))):0} HDAO</b>
                        </div>

                        
                        <div className={styles.inputParentDiv} style={{padding:'7px'}}>
                            {/* register with tag number */}
                            {
                                !isRegister
                                ?
                                <div style={{padding:'10px', border:'solid 1px rgb(62 62 62)'}}>
                                    <span style={{color:'#dfd8d8', display:'inline-block',marginBottom:'5px'}}>Refer Tag Number: </span>
                                    <input type="number" onChange={(e) => setReferTagNumber(e.target.value)} value={referTagNumber} style={{color:'#888282',width:'99%',marginBottom:'10px'}} className={styles.textInputField} placeholder="No tag number"/>
                                    <button disabled={mintLoading} style={{zIndex:999, position:'relative'}} onClick={onRegisterClick} className={styles.contributeBtn}>REGISTER</button>
                                </div>
                                :''
                            }

                            {/* approve token spend */}
                            {
                                isRegister && !isAllowance
                                ?
                                <div style={{padding:'10px', border:'solid 1px rgb(62 62 62)'}}>
                                    <span 
                                        style={{
                                            color:'#dfd8d8', 
                                            width:'100%', 
                                            marginBottom:'5px', 
                                            display:'inline-block'
                                        }}>
                                            Approve your tokens for staking:
                                        </span>
                                    <button disabled={mintLoading} style={{width:'100%',zIndex:999, position:'relative'}} onClick={onApproveClick} className={styles.contributeBtn}>APPROVE</button>
                                </div>
                                :''

                            }

                            {/* stake hdao amount */}
                            {
                                isRegister && isAllowance
                                ?
                                <div style={{padding:'10px', border:'solid 1px rgb(62 62 62)'}}>
                                    <span style={{color:'#dfd8d8', display:'inline-block',marginBottom:'10px'}}>HDAO STAKE: </span>

                                    <div style={{margin:'7px 0', background:'#388e63', borderRadius:'5px'}}>
                                        <span onClick={()=> {setStakeTopic(1)}} style={{borderRadius:'5px 0px 0px 5px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(stakeTopic==1?'#1ea1f0':'')}}>3 Months</span>
                                        <span onClick={()=> {setStakeTopic(2)}} style={{padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(stakeTopic==2?'#1ea1f0':'')}}>6 Months</span>
                                        <span onClick={()=> {setStakeTopic(3)}} style={{padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(stakeTopic==3?'#1ea1f0':'')}}>12 Months</span>
                                        <span onClick={()=> {setStakeTopic(4)}} style={{borderRadius:'0px 5px 5px 0px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(stakeTopic==4?'#1ea1f0':'')}}>48 Months</span>
                                    </div>

                                    <div style={{marginTop:'-6px', marginBottom:'7px',borderRadius:'5px', background:'#388e63', fontSize:'12px'}}>
                                        <span onClick={()=> {setStakeTopic(1)}} style={{cursor:'pointer',borderRadius:'5px 0px 0px 5px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center',background:(stakeTopic==1?'#1ea1f0':'')}}>APY: 10%</span>
                                        <span onClick={()=> {setStakeTopic(2)}} style={{cursor:'pointer',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', background:(stakeTopic==2?'#1ea1f0':'')}}>APY: 70%</span>
                                        <span onClick={()=> {setStakeTopic(3)}} style={{cursor:'pointer',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', background:(stakeTopic==3?'#1ea1f0':'')}}>APY: 300%</span>
                                        <span onClick={()=> {setStakeTopic(4)}} style={{cursor:'pointer',borderRadius:'0px 5px 5px 0px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', background:(stakeTopic==4?'#1ea1f0':'')}}>APY: 1000%</span>
                                    </div>


                                    <input onChange={(e)=> setStakeAmount(e.target.value)} value={stakeAmount} type="number" min="0" className={styles.textInputField} placeholder="Enter token amount to stake"/>
                                    <div style={{margin:'7px 0', background:'#388e63', borderRadius:'5px'}}>
                                        <span onClick={()=> {setStakeAmount(((Number(tokenBalance) > 0.000001e18 ? Number(formatEther(tokenBalance)):0)) * 25 /100); setPrAmount(25)}} style={{borderRadius:'5px 0px 0px 5px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(prAmount==25?'#1ea1f0':'')}}>25%</span>
                                        <span onClick={()=> {setStakeAmount(((Number(tokenBalance) > 0.000001e18 ? Number(formatEther(tokenBalance)):0)) * 50 /100); setPrAmount(50)}} style={{padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(prAmount==50?'#1ea1f0':'')}}>50%</span>
                                        <span onClick={()=> {setStakeAmount(((Number(tokenBalance) > 0.000001e18 ? Number(formatEther(tokenBalance)):0)) * 75 /100); setPrAmount(75)}} style={{padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(prAmount==75?'#1ea1f0':'')}}>75%</span>
                                        <span onClick={()=> {setStakeAmount(((Number(tokenBalance) > 0.000001e18 ? Number(formatEther(tokenBalance)):0)) * 100 /100); setPrAmount(100)}} style={{borderRadius:'0px 5px 5px 0px',padding:'4px 0',width:"25%", display:'inline-block', textAlign:'center', cursor:'pointer', background:(prAmount==100?'#1ea1f0':'')}}>100%</span>
                                    </div>
                                    <div>
                                        <button disabled={mintLoading} style={{zIndex:999, position:'relative'}} onClick={onClickStakeNow} className={styles.contributeBtn}>STAKE</button>
                                    </div>
                                </div>
                                :''
                                
                            }
                            {
                                showError && errorMsg !=""
                                ?
                                <span style={{display:'block',padding:'7px 5px', color:'#ee3636', border:'solid 1px #272626'}}>{errorMsg}</span>
                                :''
                            }
                        </div>
                    </div>


                    {/* staking details */}
                    <div style={{width:'100%', padding:'0px 10px'}}>
                        {
                        Number(registerDetail?registerDetail[0]:0) != 0
                        ?
                        <Typography textAlign={'center'}>Tag No: {Number(registerDetail[0])}</Typography>
                        :'' 
                        }
                    </div>

                    {
                        myStakes?.map((stake, index) => {
                            myTopics.push(Number(stake.topic))
                            return(
                                <StakeCard 
                                    key={index+1}
                                    stake={stake} 
                                    config={hdaoConfig} 
                                    address={address} 
                                    onClaimRewardClick={() => onClaimRewardClick(Number(stake.topic))}
                                    onUnstakeClick={() => onUnstakeClick(Number(stake.topic))}
                                />
                                )
                        })
                    }
                    
                    {/* socials */}
                    <div 
                        style={{
                        borderTop:'solid 1px #767676',
                        textAlign:'center', 
                        paddingTop:'10px', 
                        display:'block', 
                        width:'100%',
                        zIndex:999,
                        position:'relative'
                        }}
                    >
                        <div>
                        <a href="https://t.me/harbordao" target="_blank">
                            <img style={{width:'30px', marginLeft:'6px'}} alt="" title="Telegram" src="/telegram512.png"/>
                        </a>
                        <a href="https://twitter.com/harbordao_xyz" target="_blank" title="Twitter">
                            <img style={{width:'30px', marginLeft:'6px'}} alt="" src="/twitter.png"/>
                        </a>
                        <a href="https://docs.harbordao.xyz" target="_blank" title="Docs">
                            <img style={{width:'30px', marginLeft:'6px'}} alt="" src="/gitbook.png"/>
                        </a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Staking;
